import Grid from '@mui/material/Grid'
import cn from '@utils/helpers/cn'
import { storyblokEditable } from '@storyblok/react'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'

const MuiGridItem = ({ blok }) => {
  return (
    <Grid
      item
      xs={parseFloat(blok.mobileSize) || 12}
      sm={parseFloat(blok.tabletSize) || 12}
      lg={parseFloat(blok.desktopSize) || 12}
      {...storyblokEditable(blok)}
    >
      <div className={cn(blok.class)}>
        {blok.blocks
          ?.filter(
            (b) =>
              (b.hasOwnProperty('showBlock') && b.showBlock) ||
              !b.hasOwnProperty('showBlock'),
          )
          .map((nestedBlok, index) => (
            <StoryblokComponent
              index={index}
              blok={nestedBlok}
              key={nestedBlok._uid}
            />
          ))}
      </div>
    </Grid>
  )
}

export default MuiGridItem
